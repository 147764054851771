<template>
	<v-app>
		<v-app-bar
			app
			color="primary"
			dark
		>
			<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
			<v-toolbar-title>SALAM STUDY</v-toolbar-title>
		</v-app-bar>
		<v-navigation-drawer
			v-model="drawer"
			absolute
			temporary
		>
			<v-list
				nav
				dense
			>
				<v-list-item-group
					v-model="group"
					active-class="deep-purple--text text--accent-4"
				>
					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-home</v-icon>
						</v-list-item-icon>
						<v-list-item-title>首页</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-vuejs</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Vue</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-language-java</v-icon>
						</v-list-item-icon>
						<v-list-item-title>JAVA</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-language-php</v-icon>
						</v-list-item-icon>
						<v-list-item-title>PHP</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-alpha-s-circle</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Spring</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-icon>
							<v-icon>mdi-laravel</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Laravel</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-main>
			<router-view/>
		</v-main>
		<v-footer padless>
			<v-col
				class="text-center"
				cols="12"
			>
				<p>
					<span>备案号：</span>
					<a target="_blank" href="https://beian.miit.gov.cn/">宁ICP备2021001363号-1</a>
				</p>
			</v-col>
		</v-footer>
	</v-app>
</template>

<script>

export default {
	name: 'App',

	data: () => ({
		drawer: false,
		group: null,
	})
}
</script>
