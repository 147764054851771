<template>
  <div class="home">
	  <div style="width: 100vw;height: 500px;display:flex; align-items: center;justify-content: center;font-size: 48px">开发中。。。</div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
